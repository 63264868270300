import React from 'react';
import { useLocation } from 'react-router-dom';

import { FlowComponentType } from 'routes/types';

import Button, { ButtonType } from 'components/Button';
import FormNavigation from 'components/FormNavigation/FormNavigation';
import FormContainer from 'components/LoanForm/FormContainer/FormContainer';
import { ApplyEmployeeLoanResult } from 'enums/FlowNextResults';

import { ReactComponent as TitleIcon } from 'images/offer-available-icon.svg';

import styles from './ApplyEmployeeLoan.module.scss';

const ApplyEmployeeLoan = ({ handleNext, navigationInfo }: FlowComponentType) => {
  const stateLocation = useLocation().state as { fromCardFlow?: boolean };
  const isCardFlow = stateLocation?.fromCardFlow;

  const handleNoThanks = () => {
    handleNext(isCardFlow ? undefined : ApplyEmployeeLoanResult.ThanksForJoining);
  };

  return (
    <>
      <FormNavigation {...navigationInfo} />
      <FormContainer
        icon={<TitleIcon />}
        title={
          <>
            {isCardFlow ? 'Want' : 'While you wait, want'} to access up to a{' '}
            <span className={styles.titleHighlight}>$15,000</span> Healthcare Employee Loan?
          </>
        }
        subtitle="Access below market interest rates exclusively for healthcare professionals."
      >
        <div className={styles.disclaimerContainer}>
          By clicking Apply, you’ll begin a loan application. See{' '}
          <a href="https://www.planneryapp.com/lending/nurses" target="_blank" rel="noreferrer">
            Product Details
          </a>{' '}
          to learn more.
        </div>
        <div className={styles.buttonsContainer}>
          <Button onClick={() => handleNext(ApplyEmployeeLoanResult.Apply)}>Apply</Button>
          <Button type={ButtonType.Inverted} onClick={handleNoThanks}>
            No Thanks
          </Button>
        </div>
      </FormContainer>
    </>
  );
};

export default ApplyEmployeeLoan;
