import React from 'react';
import Button, { ButtonType } from 'components/Button';

interface BackToHomepageProps {
  text?: string;
  onClick?: () => void;
  className?: string;
}

const BackToHomepage = ({ text = 'Back to Plannery homepage', onClick, className }: BackToHomepageProps) => {
  const handleGetBack = () => {
    window.open('https://www.planneryapp.com/');
  };
  return (
    <Button className={className} type={ButtonType.Secondary} onClick={onClick || handleGetBack}>
      {text}
    </Button>
  );
};

export default BackToHomepage;
